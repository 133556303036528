import { React, useState, useEffect } from "react";
import { Layout, Anchor, Row, Col, Typography } from 'antd';
import { MenuOutlined } from '@ant-design/icons'
import Scard from '../components/scard/SCard'
import { useNavigate } from 'react-router-dom'
import QueueAnim from "rc-queue-anim";


import wFace from "../statics/worried-face.png";
import pic1 from "../statics/pic1.png";
import pic2 from "../statics/pic2.png";
import pic3 from "../statics/pic3.png";
import pic4 from "../statics/pic4.png";
import pic5 from "../statics/pic5.png";
import pic6 from "../statics/pic6.png";
const { Title } = Typography;

const { Header, Content } = Layout;

const cardData = [
    { img: pic1, title: "离线异构数据整合", content: '运用多种ETL手段，将企业内外部各种关系型、非关系型数据进行统一的标准化和规范化整合。' },
    { img: pic2, title: "打通数据壁垒", content: '统一数据接口标准，通过搭建大数据平台实现企业数据的自动采集、集中汇总，形成企业数据资产池。' },
    { img: pic3, title: "数据多维展示", content: '提供固定式报表、可视化图表、大屏驾驶舱等多种展示形式，为管理人员提供决策支持及协作。' },
    { img: pic4, title: "提高工作效率", content: '传统企业中存在大量线下手工数据，数字化平台可将手工数据快速制作成模板供员工导入并留存到数据仓库，提升信息化水平。' },
    { img: pic5, title: "透明业务流程", content: '通过汇集并抓取客户需求、目标计划、项目实施、账务报告等信息，实现对各业务全流程可视化跟踪，提高业务流程管理水平。' },
    { img: pic6, title: "跨部门间协作", content: '建立面向各职能部门的数据平台，实现公司各部门以及各层级之间的信息共享、业务分析决策、协同办公的业务诉求。' },
]


function Solution() {
    useEffect(() => {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
    }, [])
    const navi = useNavigate();
    const toToPage = (e, v) => {
        navi('/', { state: { id: e } })
    }

    const [targetOffset, setTargetOffset] = useState(100);
    const [headerOnTop, setHeaderOnTop] = useState(true)
    window.addEventListener('scroll', function (v) {
        if (window.scrollY > 0 + 100) {
            setHeaderOnTop(false)
        } else {
            setHeaderOnTop(true)
        }
    })

    const [showAnchor, setShowAnchor] = useState(false)
    const showAnchorHandle = () => {
        setShowAnchor(!showAnchor)
        setTargetOffset(targetOffset === 200 ? targetOffset - 100 : targetOffset + 100)
    }
    return <div>
        <Header className={["j-menu", showAnchor ? "header-bg2 header-bg3" : headerOnTop ? '' : 'header-bg2'].join(' ')} style={{ height: showAnchor ? '187px' : '64px', position: 'fixed', zIndex: 1, width: '100%' }}>
            <Row style={{ maxWidth: 1920, margin: 'auto' }}>
                <Col xs={{ span: 22, offset: 1 }} sm={{ span: 22 }} md={{ span: 22, offset: 1 }} xxl={{ span: 16, offset: 4 }}>
                    <Row>
                        <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 4 }}><div className="logo" /></Col>
                        <Col xs={{ span: 12 }} sm={{ span: 16 }} lg={{ span: 10, offset: 3 }} style={{ textAlign: "center" }}>
                            <MenuOutlined className="j-anchor-icon" onClick={showAnchorHandle} />
                            <div className="ant-anchor-wrapper j-anchor">
                                <div className="ant-anchor ant-anchor-fixed">
                                    <div className="p-anchor">
                                        <div className="ant-anchor-link " style={{ cursor: 'pointer' }} onClick={toToPage.bind(this, 'First')}>
                                            <div className="ant-anchor-link-title">首页</div>
                                        </div>
                                        <div className="ant-anchor-link" style={{ cursor: 'pointer' }} onClick={toToPage.bind(this, 'Second')}>
                                            <div className="ant-anchor-link-title">产品中心</div>
                                        </div>
                                        <div className="ant-anchor-link ant-anchor-link-active" style={{ cursor: 'pointer' }} onClick={toToPage.bind(this, 'Theard')}>
                                            <div className="ant-anchor-link-title">解决方案</div>
                                        </div>
                                        <div className="ant-anchor-link" style={{ cursor: 'pointer' }} onClick={toToPage.bind(this, 'Four')}>
                                            <div className="ant-anchor-link-title">联系我们</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 0 }} >
                            <Anchor className='j-anchor' style={{ overflow: 'hidden' }} affix={false} targetOffset={targetOffset} target={() => document.querySelector('#contents')}  >
                                <QueueAnim className="m-anchor" interval={[100, 0]} delay={[300, 0]}>
                                    {showAnchor ? [
                                        <div key='a' className="ant-anchor-link " style={{ cursor: 'pointer' }} onClick={toToPage.bind(this, 'First')}>
                                            <div className="ant-anchor-link-title">首页</div>
                                        </div>,
                                        <div key='b' className="ant-anchor-link" style={{ cursor: 'pointer' }} onClick={toToPage.bind(this, 'Second')}>
                                            <div className="ant-anchor-link-title">产品中心</div>
                                        </div>,
                                        <div key='c' className="ant-anchor-link ant-anchor-link-active" style={{ cursor: 'pointer' }} onClick={toToPage.bind(this, 'Theard')}>
                                            <div className="ant-anchor-link-title">解决方案</div>
                                        </div>,
                                        <div key='d' className="ant-anchor-link" style={{ cursor: 'pointer' }} onClick={toToPage.bind(this, 'Four')}>
                                            <div className="ant-anchor-link-title">联系我们</div>
                                        </div>,
                                    ] : null}
                                </QueueAnim>
                            </Anchor>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Header>
        <Content>
            <div id='contents' style={{ maxWidth: 'none' }}>
                <div className="site-layout-content s-first">
                    <div style={{ maxWidth: 1920, margin: 'auto' }}>
                        <Row>
                            <Col sm={{ span: 22 }} md={{ span: 22, offset: 1 }} xxl={{ span: 16, offset: 4 }}>
                                <Row justify="space-between" align="middle" style={{ height: '500px' }}>
                                    <Col xs={20} sm={12} lg={8}>
                                        <div style={{ color: '#158AFE', fontSize: '18px', letterSpacing: '2px' }}>
                                            <span className='j-line2'></span>
                                        </div>
                                        <div style={{ margin: '20px 0' }}>
                                            <Title className='tittle-color' style={{ fontSize: '40px' }}>解决方案</Title>
                                        </div>
                                        <div>
                                            <span className='content-fs content-color'>
                                                通过快速连接融合多种异构数据，帮助企业打破数据壁垒，实现大数据建设和应用落地，提供多样的数据处理和数据可视化展示平台、数据治理咨询评价、价值运营等端到端的数据工程化服务。
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>


                <div className="site-layout-content s-second">
                    <div style={{ maxWidth: 1920, margin: 'auto' }}>
                        <Row>
                            <Col md={{ span: 22, offset: 1 }} xxl={{ span: 16, offset: 4 }} style={{ textAlign: 'center' }}>
                                <div className='tittle-color' style={{ fontSize: '32px', margin: '10px 0 0 0' }}>企业信息化建设困境</div>
                                <div className='content-fs content-color' style={{ maxWidth: 750, margin: '15px auto 0' }}>各企业均拥有大量的信息系统，如 OA、CRM、ERP 以及各种 SaaS 等系统，系统之间并不能高效连通，从而形成了数据孤岛，无法进行有效的数据联合分析，导致数据无法发挥其最大价值。</div>
                            </Col>
                        </Row>
                        <Row className="distence-80">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 22, offset: 1 }} xxl={{ span: 16, offset: 4 }}>
                                <Row className="ant-card-actions big-card" style={{}}>
                                    <Col xs={24} sm={8} style={{}}>
                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ verticalAlign: 'sub' }} src={wFace}
                                                width={30} alt=""
                                            />
                                        </div>
                                        <div className='tittle-color tittle-fs' style={{ textAlign: 'center', margin: 10 }}>
                                            数据处理效率低
                                        </div>
                                        <div className='content-fs content-color' style={{ maxWidth: 250, padding: '0 5px', margin: 'auto' }}>
                                            多业务系统的多源异构数据，没有办法统一处理，数据质量参差不齐、口径不一致。
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={8} style={{}}>
                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ verticalAlign: 'sub' }} src={wFace}
                                                width={30} alt=""
                                            />
                                        </div>
                                        <div className='tittle-color tittle-fs' style={{ textAlign: 'center', margin: 10 }}>
                                            数据管理难度大
                                        </div>
                                        <div className='content-fs content-color' style={{ maxWidth: 310, padding: '0 5px', margin: 'auto' }}>
                                            分散的业务系统数据分析难以在统一平台管理，权限管理繁琐，处理和运维需要依赖专业IT技术人才，无法高效进行数据共享。
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={8} style={{}}>
                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ verticalAlign: 'sub' }} src={wFace}
                                                width={30} alt=""
                                            />
                                        </div>
                                        <div className='tittle-color tittle-fs' style={{ textAlign: 'center', margin: 10 }}>
                                            降低运营决策效率
                                        </div>
                                        <div className='content-fs content-color' style={{ maxWidth: 250, padding: '0 5px', margin: 'auto' }}>
                                            在需要进行运营决策而提供数据分析的时候，需要花费较长的时间提前进行数据处理。
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="site-layout-content s-theard">
                    <div style={{ maxWidth: 1920, margin: 'auto' }}>
                        <Row>
                            <Col md={{ span: 22, offset: 1 }} xxl={{ span: 16, offset: 4 }} style={{ textAlign: 'center' }}>
                                <div className='tittle-color' style={{ fontSize: '32px' }}>如何助力企业降本增效</div>
                            </Col>
                        </Row>
                        <Row className="distence-80">
                            <Col xs={{ span: 24 }} sm={{ span: 22, offset: 1 }} lg={{ span: 22, offset: 1 }} xxl={{ span: 16, offset: 4 }}>
                                <Row gutter={[30, 30]}>
                                    <Col xs={24} md={{ span: 8 }}>
                                        <Scard datas={cardData[0]} />
                                    </Col>
                                    <Col xs={24} md={{ span: 8 }}>
                                        <Scard datas={cardData[1]} />
                                    </Col>
                                    <Col xs={24} md={{ span: 8 }}>
                                        <Scard datas={cardData[2]} />
                                    </Col>
                                    <Col xs={24} md={{ span: 8 }}>
                                        <Scard datas={cardData[3]} />
                                    </Col>
                                    <Col xs={24} md={{ span: 8 }}>
                                        <Scard datas={cardData[4]} />
                                    </Col>
                                    <Col xs={24} md={{ span: 8 }}>
                                        <Scard datas={cardData[5]} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </Content>
    </div>
}

export default Solution;