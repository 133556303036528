
import './App.less';
import {useRoutes} from 'react-router-dom'
import jRouter from './router';

function App() {
  const r = useRoutes(jRouter)
  return r
}

export default App;
