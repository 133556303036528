import React, { Component } from 'react';

export default class Jcard extends Component {

    componentDidMount() {
    }
    //子组件被调用方法
    // test(val) {
    //     alert('我是测试方法' + val)
    // }

    render() {
        return <div className='j-card'>
            <div className={[`t-img ${this.props.datas.img}`]}></div>
            <div style={{
                width: 'max-content',
                margin: 'auto',
                textAlign: 'left'
            }}>
                <div className='j-card-tittle tittle-fs tittle-color'>
                    {this.props.datas.title}
                </div>
                <div className='j-card-content content-fs content-color'>
                    {this.props.datas.content.map((item, index) => {
                        return <div key={index}>
                            <span>
                                {item}
                            </span>
                        </div>
                    })}
                </div>
            </div>

        </div>
    }
}
