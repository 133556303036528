import { React, useState, useEffect, useRef } from "react";
import { Layout, Anchor, Row, Col, Button, Typography, Carousel } from 'antd';
import { RightOutlined, LeftOutlined, MenuOutlined } from '@ant-design/icons'
import QueueAnim from "rc-queue-anim";

import { useNavigate, useLocation } from 'react-router-dom'

import Jcard from '../components/jcard/JCard'
import Jcollapse from '../components/jcollapse/JCollapse';



const { Header, Content} = Layout;
const { Link } = Anchor;
const { Title } = Typography;

const cardData = [
    { img: 'img1', title: "数据可视化系统", content: ['丰富的图表类型', '优秀的动态效果', '强大交互体验', '自适应PC、手机、大屏'] },
    { img: 'img4', title: "大数据决策系统", content: ['多链路分布式数据采集', '离线异构数据同步', '可视化配置权限管理', '多样化业务决策报表'] },
    { img: 'img2', title: "企业劳动力管理系统", content: ['灵活的人员排班管理', '高效自定义的班种组合', '智能化的排班规划', 'PC、安卓、小程序多端覆盖'] },
    { img: 'img3', title: "智能车辆管理系统", content: ['车辆数字化档案', '软硬件结合多场景覆盖', '智能化车辆分派', 'PC、安卓、小程序多端覆盖'] }
]



function Home() {
    const carouselEL = useRef(null);
    const navi = useNavigate();
    const toToPage = () => {
        navi('/solution')
    }

    const location = useLocation();
    const [showContent2, setShowContent2] = useState(false);
    const handleClick = (e, link) => {
        e.preventDefault();
        // if (link.href) {
        //     // 找到锚点对应得的节点
        //     let element = document.getElementById(link.href);
        //     // 如果对应id的锚点存在，就跳滚动到锚点顶部
        //     element && element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        //     if (link.href === "#Second") {
        //         setShowContent2(!showContent2)
        //     }
        // }
    };

    const [targetOffset, setTargetOffset] = useState(100);
    useEffect(() => {
        scrollingTo()
    }, []);

    const changeHandle = (currentActiveLink) => {
        if (currentActiveLink === "#Second") {
            setShowContent2(!showContent2)
        }
    }

    const firstRef = useRef();
    const secondRef = useRef();
    const theardRef = useRef();
    const fourRef = useRef();
    const scrollingTo = (event) => {
        let idName = location.state && location.state.id
        if (idName !== 'First' && idName !== 'Second' && idName !== 'Theard' && idName !== 'Four') {
            return
        }
        const element = document.getElementById(idName);
        const headerOffset = 100;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
        navi('/', { state: null })
    };

    const [headerOnTop, setHeaderOnTop] = useState(true)
    window.addEventListener('scroll', function (v) {
        if (window.scrollY > 0 + 100) {
            setHeaderOnTop(false)
        } else {
            setHeaderOnTop(true)
        }
    })

    const [showAnchor, setShowAnchor] = useState(false)
    const showAnchorHandle = () => {
        setShowAnchor(!showAnchor)
        setTargetOffset(targetOffset===200?targetOffset-100:targetOffset+100)
        // setHeaderOnTop(false)
    }


    function concactUs(){
        window.open('https://wj.qq.com/s2/11144577/0662/')
    }
    return (
        <div>
            <Header className={["j-menu", showAnchor ? "header-bg2 header-bg3" : headerOnTop ? '' : 'header-bg2'].join(' ')} style={{ height:showAnchor?'187px':'64px',position: 'fixed', zIndex: 1, width: '100%' }}>
                <Row style={{ maxWidth: 1920, margin: 'auto' }}>
                    <Col xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 22, offset: 1 }} xxl={{ span: 16, offset: 4 }}>
                        <Row>
                            <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 4 }}><div className="logo" /></Col>
                            <Col xs={{ span: 12 }} sm={{ span: 16 }} lg={{ span: 10, offset: 3 }} style={{ textAlign: "center" }}>
                                <MenuOutlined className="j-anchor-icon" onClick={showAnchorHandle} />
                                <Anchor className='j-anchor' style={{ overflow: 'hidden' }} affix={false} targetOffset={targetOffset} onChange={changeHandle} onClick={handleClick} target={() => document.querySelector('#contents')}  >
                                    <div className="p-anchor">
                                        <Link href="#First" title="首页" />
                                        <Link href="#Second" title="产品中心" />
                                        <Link href="#Theard" title="解决方案" />
                                        <Link href="#Four" title="联系我们" />
                                    </div>
                                </Anchor>
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 0 }} >
                                <Anchor className='j-anchor' style={{ overflow: 'hidden' }} affix={false} targetOffset={targetOffset} onChange={changeHandle} onClick={handleClick} target={() => document.querySelector('#contents')}  >
                                    <QueueAnim className="m-anchor" interval={[100,0]} delay={[300,0]}>
                                        {showAnchor ? [
                                            <div key='a'><Link href="#First" title="首页" /></div>,
                                            <div key='b'><Link href="#Second" title="产品中心" /></div>,
                                            <div key='c'><Link href="#Theard" title="解决方案" /></div>,
                                            <div key='d'><Link href="#Four" title="联系我们" /></div>
                                        ] : null}
                                    </QueueAnim>
                                </Anchor>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Header>
            <Content>
                <div id='contents' >
                    <div id="First" className="site-layout-content" ref={firstRef}>
                        <Row>
                            <Col sm={{ span: 22 }} md={{ span: 22, offset: 1 }} xxl={{ span: 16, offset: 4 }}>
                                <Row justify="space-between" align="middle" style={{ height: '600px' }}>
                                    <Col md={24} lg={12}>
                                        <div style={{ color: '#158AFE', fontSize: '18px', letterSpacing: '2px' }}>
                                            <span className='j-line'></span> 玖磐智能科技
                                        </div>
                                        <div style={{ margin: '50px 0' }}>
                                            <h1 className='tittle-color logo-font'>科技让企业更高效</h1>
                                        </div>
                                        <div>
                                            <Button className='j-btn' type="primary" shape="round" size={'large'}  onClick={concactUs} style={{border: 'none'}}>
                                                联系我们 <RightOutlined />
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div id="Second" className="site-layout-content" ref={secondRef}>
                        <Row>
                            <Col md={{ span: 22, offset: 1 }} xxl={{ span: 16, offset: 4 }} className="relative">
                                <Title style={{ fontSize: 24, color: '#158AFE', marginBottom: 0 }}>PRODUCT</Title>
                                <div className='tittle-color' style={{ fontSize: '32px' }}>我们的产品</div>
                                <div className='content-fs content-color'>提供多种数字化、智能化产品和服务，帮助企业降本增效，提高信息化水平</div>
                                <div className='lattice' />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 80, marginBottom: 80 }}>
                            <Col xs={{ span: 24 }} sm={{ span: 22, offset: 1 }} lg={{ span: 0 }}>
                                <Row gutter={[{ xs: 50 }, { xs: 20, sm: 20 }]} justify={'space-between'}>
                                    <Col xs={{ span: 24 }}>
                                        <Jcollapse datas={cardData} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{ span: 0 }} lg={{ span: 22, offset: 1 }} xxl={{ span: 16, offset: 4 }}>
                                <Row gutter={[{ lg: 20, xl: 38 }]} justify={'space-between'}>
                                    <Col lg={{ span: 6 }} >
                                        <Jcard datas={cardData[0]} />
                                    </Col>
                                    <Col lg={{ span: 6 }}>
                                        <Jcard datas={cardData[1]} />
                                    </Col>
                                    <Col lg={{ span: 6 }}>
                                        <Jcard datas={cardData[2]} />
                                    </Col>
                                    <Col lg={{ span: 6 }}>
                                        <Jcard datas={cardData[3]} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div id="Theard" className="site-layout-content" ref={theardRef}>
                        <Row>
                            <Col md={{ span: 12, offset: 1 }} xxl={{ span: 8, offset: 4 }} className="relative">
                                <Title style={{ fontSize: 24, color: '#158AFE', marginBottom: 0 }}>SOLUTION</Title>
                                <div className='tittle-color' style={{ fontSize: '32px' }}>解决方案</div>
                                <div className='content-fs content-color'>提供一体化整体解决方案，加速企业信息化改造和建设效率</div>
                                <div className='lattice' style={{ left: 150 }} />
                            </Col>
                            <Col md={{ span: 10 }} xxl={{ span: 8 }}>
                                <Button
                                    className="leftButton"
                                    style={{ right: 75 }}
                                    onClick={() => {
                                        carouselEL.current.prev();
                                    }}
                                    icon={<LeftOutlined />}
                                ></Button>
                                <Button
                                    className="rightButton"
                                    style={{ right: 0 }}
                                    onClick={() => {
                                        carouselEL.current.next();
                                    }}
                                    icon={<RightOutlined />}
                                ></Button>
                            </Col>
                        </Row>
                        <Row className="distence-80">
                            <Col md={{ span: 22, offset: 1 }} xxl={{ span: 16, offset: 4 }}>
                                <Carousel autoplay dots={false} ref={carouselEL}>
                                    <div>
                                        <Row>
                                            <Col md={{ span: 12 }} className="carousel-img carousel1"></Col>
                                            <Col md={{ span: 11, offset: 1 }} className="carousel-content">
                                                <div className="solu-content" style={{ textAlign: 'end' }}>
                                                    <div className='tittle-color tittle-fs'>多链路数据建设和治理</div>
                                                    <div className='content-fs content-color'>帮助用户从企业内外部海量数据中，发现商业契机、生产隐患、成本漏洞等各类潜在的高价值信息</div>
                                                    <div>
                                                        <Button style={{border: 'none'}} className='j-btn' type="primary" shape="round" size={'large'} onClick={toToPage}>
                                                            了解详情 <RightOutlined />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        <Row>
                                            <Col md={{ span: 11 }} className="carousel-content">
                                                <div className="solu-content">
                                                    <div className='tittle-color tittle-fs'>企业降本增效信息化改造</div>
                                                    <div className='content-fs content-color'>全面深入了解企业需求，结合实际日常业务，为企业制定整体改造方案，充分利用信息化、智能化技术，帮助企业节约成本、提高管理效率。</div>
                                                    <div>
                                                        <Button style={{border: 'none'}} className='j-btn' type="primary" shape="round" size={'large'} onClick={toToPage}>
                                                            了解详情 <RightOutlined />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={{ span: 11, offset: 2 }} className="carousel-img carousel2"></Col>
                                        </Row>
                                    </div>
                                </Carousel>
                            </Col>
                        </Row>
                    </div>

                </div>
                <div id="Four" className="site-layout-content" ref={fourRef}>
                    <div>
                        <span style={{ fontSize: 48 }}>项目合作</span>
                        <br />
                        <div style={{ fontSize: 32, marginTop: 20 }}>留下您的联系方式</div>
                    </div>
                    <div>
                        <Button style={{color:'#158AFE',border: 'none'}} shape="round" size={'large'} onClick={concactUs} >
                            联系我们 <RightOutlined />
                        </Button>
                    </div>
                </div>
            </Content>
        </div>
    );
}

export default Home;