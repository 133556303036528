import React, { Component } from 'react';
import { Collapse } from 'antd'

const { Panel } = Collapse

export default class Jcollapse extends Component {
  componentDidMount() {
  }
  render() {
    return <Collapse expandIconPosition="end" className='custom-collapse' ghost>
      {this.props.datas.map((item, index) => {
        return <Panel key={index} header={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className={[`m-img ${item.img}`]}></div>
            <div className='tittle-fs tittle-color'>
              {item.title}
            </div>
          </div>
        } className='custom-collapse-panel'>
          <div style={{display: "flex"}}>
            <div className={[`m-img`]}></div>
            <div className='content-fs content-color' style={{ lineHeight: '30px' }}>
              {item.content.map((v, i) => {
                return <div key={i}>
                  <span>
                    {v}
                  </span>
                </div>
              })}
            </div>
          </div>

        </Panel>
      })}
    </Collapse>
  }
}
