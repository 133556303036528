import RouteObject from 'react-router-dom';
import JLayout from '../jLayout';
import Home from '../page/home';
import Solution from '../page/solution';

const jRouter: RouteObject[] = [
    {
        path: '/',
        element: <JLayout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: '/solution',
                element: <Solution />
            }
        ]
    }
]

export default jRouter;