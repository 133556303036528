import { React } from "react";
import { Outlet } from "react-router-dom";
import { Layout, Row, Col } from 'antd';
import { MinusOutlined } from '@ant-design/icons'

import beian from "./statics/beian.png";
const { Footer } = Layout;





function JLayout() {



    return (
        <Layout className="layout" style={{ background: '#F2F4F6' }}>
            <Outlet />
            <Footer className='footer'>
                <div style={{ maxWidth: 1920, margin: 'auto' }}>
                    <Row style={{ lineHeight: "34px" }}>
                        <Col xs={16} md={{ span: 6, offset: 1 }} xxl={{ span: 3, offset: 4 }} >
                            <div>联系方式</div>
                            <div className='content-fs content-color'>
                                <span style={{ borderBottom: '1px solid #888' }}>029-89535699</span>
                                <br />
                                <span style={{ borderBottom: '1px solid #888' }}>+86 13629273395</span>
                                <br />
                                <span style={{ borderBottom: '1px solid #888' }}>陕西省西安市碑林区交大街</span>
                            </div>
                            <div style={{ margin: "36px 0" }}>
                                <MinusOutlined style={{ color: '#444' }} />
                            </div>
                        </Col>
                        <Col xs={8} md={{ span: 6, offset: 1 }} xxl={{ span: 3, offset: 1 }}>
                            <div>电子邮箱</div>
                            <div className='content-fs content-color'>
                                <span style={{ borderBottom: '1px solid #888' }}>lybhyf@163.com</span>
                                <br />
                                <span>&ensp;</span>
                                <br />
                                <span>&ensp;</span>
                            </div>
                            <div style={{ margin: "36px 0" }}>
                                <MinusOutlined style={{ color: '#444' }} />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[0, 5]} className="copyright content-color">
                        <Col sm={{ span: 20, offset: 1 }} lg={{ span: 8, offset: 1 }} xxl={{ span: 6, offset: 4 }}>
                            Copyright 2018-2022  版权所有  西安玖磐智能科技有限公司
                        </Col>
                        <Col sm={{ span: 20, offset: 1 }} lg={{ span: 8, offset: 0 }} xxl={{ span: 6, offset: 0 }}>
                            <img style={{ verticalAlign: 'sub' }} src={beian}
                                width={20} alt=""
                            /> 陕公网安备 61010202000254号    陕ICP备2021011373号
                        </Col>
                    </Row>
                </div>

            </Footer>
        </Layout >
    );
}

export default JLayout;