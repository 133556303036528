import { React, Component } from "react";


export default class Scard extends Component {
    componentDidMount() {
        // console.log(this.props)
    }
    render() {
        return <div style={{
            width: '100%', height: 317, padding: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.2)',
            borderRadius: 24,
            background: '#FFFFFF'
        }}>
            <div style={{ textAlign: 'center', margin: '10px 0' }}>
                <img style={{ verticalAlign: 'sub' }} src={this.props.datas.img} onClick={false}
                    width={100} height={100} alt={this.props.datas.title} />
            </div>
            <div className='tittle-color tittle-fs' style={{ textAlign: 'center', margin: 10 }}>
                {this.props.datas.title}
            </div>
            <div className='content-fs' style={{ maxWidth: 300, margin: 'auto', color: '#3D3D3D' }}>
                {this.props.datas.content}
            </div>
        </div>
    }
}